import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/home' },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'login'
  },
  {
    path: '/home',
    component: () => import('@/views/Home/Home.vue'),
    name: 'home',
    redirect: '/home/main',
    children: [
      {
        path: 'main',
        component: () => import('@/views/Main/Main.vue'),
        name: 'main'
      },
      {
        path: 'register',
        component: () => import('@/views/Register/Register.vue'),
        name: 'register'
      },
      {
        path: 'ReleaseInfor',
        component: () => import('@/views/ReleaseInfor/ReleaseInfor.vue'),
        name: 'ReleaseInfor'
      },
      {
        path: 'merchants',
        component: () => import('@/views/merchants/merchants.vue'),
        name: 'merchants'
      },
      {
        path: 'ShopList',
        component: () => import('@/views/ShopList/shopList.vue'),
        name: 'ShopList'
      },
      {
        path: 'creative',
        component: () => import('@/views/creative/creative.vue'),
        name: 'creative'
      },
      {
        path: 'wonkShop',
        component: () => import('@/views/wonkShop/wonkShop.vue'),
        name: 'wonkShop'
      },
      {
        path: 'timeShop',
        component: () => import('@/views/timeShop/timeShop.vue'),
        name: 'timeShop'
      },
      {
        path: 'merchantForm',
        component: () => import('@/views/merchantForm/merchantForm.vue'),
        name: 'merchantForm'
      },
      {
        path: 'payPage',
        component: () => import('@/views/payPage/payPage.vue'),
        name: 'payPage'
      },
      {
        path: 'ArtDetails1',
        component: () => import('@/views/ArtDetails/ArtDetails1.vue'),
        name: 'ArtDetails1'
      },
      {
        path: 'ArtDetails2',
        component: () => import('@/views/ArtDetails/ArtDetails2.vue'),
        name: 'ArtDetails2'
      },
      {
        path: 'ArtDetails3',
        component: () => import('@/views/ArtDetails/ArtDetails3.vue'),
        name: 'ArtDetails3'
      },
      {
        path: 'ArtDetails4',
        component: () => import('@/views/ArtDetails/ArtDetails4.vue'),
        name: 'ArtDetails4'
      },
      {
        path: 'ArtDetails5',
        component: () => import('@/views/ArtDetails/ArtDetails5.vue'),
        name: 'ArtDetails5'
      },
      {
        path: 'ArtDetails6',
        component: () => import('@/views/ArtDetails/ArtDetails6.vue'),
        name: 'ArtDetails6'
      },
      {
        path: 'ArtDetails7',
        component: () => import('@/views/ArtDetails/ArtDetails7.vue'),
        name: 'ArtDetails7'
      },
      {
        path: 'ArtDetails8',
        component: () => import('@/views/ArtDetails/ArtDetails8.vue'),
        name: 'ArtDetails8'
      },
      {
        path: 'ArtDetails9',
        component: () => import('@/views/ArtDetails/ArtDetails9.vue'),
        name: 'ArtDetails9'
      },
      {
        path: 'ArtDetails10',
        component: () => import('@/views/ArtDetails/ArtDetails10.vue'),
        name: 'ArtDetails10'
      },
      {
        path: 'ArtDetails11',
        component: () => import('@/views/ArtDetails/ArtDetails11.vue'),
        name: 'ArtDetails11'
      },
      {
        path: 'ArtDetails12',
        component: () => import('@/views/ArtDetails/ArtDetails12.vue'),
        name: 'ArtDetails12'
      },
      {
        path: 'ArtDetails13',
        component: () => import('@/views/ArtDetails/ArtDetails13.vue'),
        name: 'ArtDetails13'
      },
      {
        path: 'ArtDetails14',
        component: () => import('@/views/ArtDetails/ArtDetails14.vue'),
        name: 'ArtDetails14'
      },
      {
        path: 'ArtDetails15',
        component: () => import('@/views/ArtDetails/ArtDetails15.vue'),
        name: 'ArtDetails15'
      },
      {
        path: 'ArtDetails16',
        component: () => import('@/views/ArtDetails/ArtDetails16.vue'),
        name: 'ArtDetails16'
      },
      {
        path: 'ComicDetails1',
        component: () => import('@/views/ComicDetails/ComicDetails1.vue'),
        name: 'ComicDetails1'
      },
      {
        path: 'ComicDetails2',
        component: () => import('@/views/ComicDetails/ComicDetails2.vue'),
        name: 'ComicDetails2'
      },
      {
        path: 'ComicDetails3',
        component: () => import('@/views/ComicDetails/ComicDetails3.vue'),
        name: 'ComicDetails3'
      },
      {
        path: 'ComicDetails4',
        component: () => import('@/views/ComicDetails/ComicDetails4.vue'),
        name: 'ComicDetails4'
      },
      {
        path: 'authorSpecial1',
        component: () => import('@/views/authorList/authorSpecial1.vue'),
        name: 'authorSpecial1'
      },
      {
        path: 'authorSpecial2',
        component: () => import('@/views/authorList/authorSpecial2.vue'),
        name: 'authorSpecial2'
      },
      {
        path: 'authorSpecial3',
        component: () => import('@/views/authorList/authorSpecial3.vue'),
        name: 'authorSpecial3'
      },
      {
        path: 'authorSpecial4',
        component: () => import('@/views/authorList/authorSpecial4.vue'),
        name: 'authorSpecial4'
      },
      {
        path: 'authorSpecial5',
        component: () => import('@/views/authorList/authorSpecial5.vue'),
        name: 'authorSpecial5'
      },
      {
        path: 'authorSpecial6',
        component: () => import('@/views/authorList/authorSpecial6.vue'),
        name: 'authorSpecial6'
      },
      {
        path: 'authorSpecial7',
        component: () => import('@/views/authorList/authorSpecial7.vue'),
        name: 'authorSpecial7'
      },
      {
        path: 'authorSpecial8',
        component: () => import('@/views/authorList/authorSpecial8.vue'),
        name: 'authorSpecial8'
      },
      {
        path: 'sceneryDetails1',
        component: () => import('@/views/sceneryDetails/sceneryDetails1.vue'),
        name: 'sceneryDetails1'
      },
      {
        path: 'sceneryDetails2',
        component: () => import('@/views/sceneryDetails/sceneryDetails2.vue'),
        name: 'sceneryDetails2'
      },
      {
        path: 'sceneryDetails3',
        component: () => import('@/views/sceneryDetails/sceneryDetails3.vue'),
        name: 'sceneryDetails3'
      },
      {
        path: 'sceneryDetails4',
        component: () => import('@/views/sceneryDetails/sceneryDetails4.vue'),
        name: 'sceneryDetails4'
      },
      {
        path: 'sceneryDetails5',
        component: () => import('@/views/sceneryDetails/sceneryDetails5.vue'),
        name: 'sceneryDetails5'
      },
      {
        path: 'sceneryDetails6',
        component: () => import('@/views/sceneryDetails/sceneryDetails6.vue'),
        name: 'sceneryDetails6'
      },
      {
        path: 'sceneryDetails7',
        component: () => import('@/views/sceneryDetails/sceneryDetails7.vue'),
        name: 'sceneryDetails7'
      },
      {
        path: 'sceneryDetails8',
        component: () => import('@/views/sceneryDetails/sceneryDetails8.vue'),
        name: 'sceneryDetails8'
      },
      {
        path: 'sceneryDetails9',
        component: () => import('@/views/sceneryDetails/sceneryDetails9.vue'),
        name: 'sceneryDetails9'
      },
      {
        path: 'sceneryDetails10',
        component: () => import('@/views/sceneryDetails/sceneryDetails10.vue'),
        name: 'sceneryDetails10'
      },
      {
        path: 'sceneryDetails11',
        component: () => import('@/views/sceneryDetails/sceneryDetails11.vue'),
        name: 'sceneryDetails11'
      },
      {
        path: 'sceneryDetails12',
        component: () => import('@/views/sceneryDetails/sceneryDetails12.vue'),
        name: 'sceneryDetails12'
      },
      {
        path: 'sceneryDetails13',
        component: () => import('@/views/sceneryDetails/sceneryDetails13.vue'),
        name: 'sceneryDetails13'
      },
      {
        path: 'sceneryDetails14',
        component: () => import('@/views/sceneryDetails/sceneryDetails14.vue'),
        name: 'sceneryDetails14'
      },
      {
        path: 'sceneryDetails15',
        component: () => import('@/views/sceneryDetails/sceneryDetails15.vue'),
        name: 'sceneryDetails15'
      },
      {
        path: 'sceneryDetails16',
        component: () => import('@/views/sceneryDetails/sceneryDetails16.vue'),
        name: 'sceneryDetails16'
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
